import React from 'react';

import ContentMainColumn from '../components/ContentMainColumn';
import PageLayout from '../components/PageLayout';

const NotFoundPage: React.FC = () => (
  <PageLayout
    renderHeader={() => null}
    seo={{
      title: '404: Not found'
    }}
  >
    {() => (
      <ContentMainColumn>
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </ContentMainColumn>
    )}
  </PageLayout>
);

export default NotFoundPage;
